import React from "react";
import { createGlobalStyle } from "styled-components";
import { TopNav } from "../components/TopNav";
import { Banner } from "../components/Banner";
import { Home } from "../components/Home";
import { Address } from "../components/Address";
import { BannerNew } from "../components/BannerNew";
import { FSPForm } from "../components/FSPForm";
import { Footer } from "../components/Footer";
import { GalugaSlider } from "../components/GalugaSlider";

const GlobalStyle = createGlobalStyle`
  * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: 'Lato', 'Arial Narrow', Arial, sans-serif;
    }`;

const Index: React.FC = () => {
  return (
    <>
      <GlobalStyle />
      <TopNav />
      <GalugaSlider />
      <Address />
      <BannerNew />
      <FSPForm />
      <Footer />
    </>
  );
};

export default Index;
