import React from "react";
import styled from "styled-components";
import { Container } from "./Components";
import { breakpoints } from "../utils/styledResponsive";
import { StaticImage } from "gatsby-plugin-image";

const Map = styled.div`
  background-color: #ccc;
  height: 300px;
  width: 300px;
  margin: auto;
  margin-bottom: 32px;
`;

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 32px;
  ${breakpoints("flex-direction", ["column", "column", "row", "row"])}
`;

const AddressTextContainer = styled.p``;

const AddressTitle = styled.h3`
  color: #649767;
  margin-bottom: 16px;
`;

const AddressLine = styled.p`
  font-weight: 300;
  margin-bottom: 4px;
  opacity: 0.7;
`;

const AddressItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Address: React.FC = () => {
  return (
    <Container style={{ marginTop: 64, marginBottom: 64 }}>
      <AddressContainer>
        <AddressItem>
          <Map>
            <StaticImage
              src="../images/map-1.jpg"
              alt="New Recife - Boa Viagem"
            />
          </Map>
          <AddressTextContainer>
            <AddressTitle>New Recife - Boa Viagem</AddressTitle>
            <AddressLine>Antonio Falcão, 821</AddressLine>
            <AddressLine>Boa Viagem - Recife - Pernambuco</AddressLine>
            <AddressLine>CEP 51020-240</AddressLine>
            <AddressLine>(81) 99721-6633</AddressLine>
          </AddressTextContainer>
        </AddressItem>
        <AddressItem>
          <Map>
            <StaticImage
              src="../images/map-2.jpg"
              alt="New Recife - Casa Forte"
            />
          </Map>
          <AddressTextContainer>
            <AddressTitle>New Recife - Casa Forte</AddressTitle>
            <AddressLine>Av. 17 de Agosto, 1640</AddressLine>
            <AddressLine>Casa Forte - Recife - Pernambuco</AddressLine>
            <AddressLine>CEP 52061-540</AddressLine>
            <AddressLine>(81) 99745-1571</AddressLine>
          </AddressTextContainer>
        </AddressItem>
      </AddressContainer>
    </Container>
  );
};
