import React from "react";
import styled from "styled-components";
import { ValidationError } from "./Components";

interface Props {
  label: string;
  name: string;
  register: any;
  errors: any;
  type?: string;
  rules?: any;
  disabled?: boolean;
  helpText?: string;
  maxLength?: number;
  isTextArea?: boolean;
}

export const Input = styled.input`
  border: 1px solid #e4e4e4;
  background-color: transparent;
  padding: 8px;
  width: 100%;
  height: 40px;
  color: #fff;
`;

export const Label = styled.label`
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 8px;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 24px;
  width: 100%;
`;

export const TextArea = styled(Input)`
  height: 120px;
  background-color: transparent;
  color: #fff;
`;

export const InputField: React.FC<Props> = ({
  label,
  name,
  register,
  errors,
  type = "text",
  rules = {},
  disabled = false,
  helpText = null,
  maxLength = 50,
  isTextArea = false,
}): JSX.Element => {
  const renderErrors = () => {
    if (errors && errors[name]) {
      return <ValidationError>Preenchimento inválido</ValidationError>;
    }
    return <span />;
  };

  if (isTextArea) {
    return (
      <FormGroup>
        <Label htmlFor={name}>{label}</Label>
        <TextArea
          className="form-control"
          {...register(name, { ...rules })}
          disabled={disabled}
          maxLength={maxLength}
        />
      </FormGroup>
    );
  }
  return (
    <FormGroup>
      <Label htmlFor={name}> {label} </Label>
      <Input
        type={type}
        className="form-control"
        {...register(name, { ...rules })}
        disabled={disabled}
        maxLength={maxLength}
      />
      {renderErrors()}
    </FormGroup>
  );
};
