import { Link } from "gatsby";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from "styled-components";
import "./carousel.css";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { breakpoints } from "../utils/styledResponsive";

const Slide = styled(Link)`
  display: flex;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
`;

const SliderContainer = styled.div`
  height: auto;
  width: 100%;
  display: none;
  ${breakpoints("display", ["none", "none", "block", "block"])}
`;

const SliderMobile = styled.div`
  height: auto;
  width: 100%;
  display: block;
  ${breakpoints("display", ["block", "block", "none", "none"])}
`;

export const GalugaSlider = () => {
  return (
    <>
      <SliderContainer>
        <Carousel showThumbs={false} autoPlay>
          <Slide to="#">
            <StaticImage
              src="../images/banners/banner-new-desktop-1.jpg"
              alt="Trenta"
              quality={100}
            />
          </Slide>
          <Slide to="#">
            <StaticImage
              src="../images/banners/banner-new-desktop-2.jpg"
              alt="Trenta"
            />
          </Slide>
        </Carousel>
      </SliderContainer>
      <SliderMobile>
        <Carousel showThumbs={false}>
          <Slide to="#">
            <StaticImage
              src="../images/banners/banner-new-mobile-1.jpg"
              alt="Trenta"
              quality={100}
            />
          </Slide>
          <Slide to="#">
            <StaticImage
              src="../images/banners/banner-new-mobile-2.jpg"
              alt="Trenta"
            />
          </Slide>
        </Carousel>
      </SliderMobile>
    </>
  );
};
