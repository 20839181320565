import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

interface BannerDellAnnoProps {}

const BannerContainer = styled.div`
  height: 475px;
  overflow: hidden;
  position: relative;
  display: none;
  @media screen and (min-width: 501px) {
    display: block;
  }
`;

const BannerBox = styled.div`
  background: #649767cc;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  padding: 32px;
  font-size: 28px;
  transition: background 0.3s;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  &:hover {
    background: #649767;
  }
`;

const BannerBoxText = styled.p`
  font-family: "Roboto", sans-serif;
  font-weight: 100;
`;

const BannerContainerMobile = styled.div`
  height: 475px;
  overflow: hidden;
  position: relative;
  display: block;
  @media screen and (min-width: 501px) {
    display: none;
  }
`;

export const BannerNew: React.FC<BannerDellAnnoProps> = ({}) => {
  return (
    <>
      <BannerContainer>
        <StaticImage src="../images/banner-bottom.jpg" alt="New Móveis" />
        <BannerBox
          onClick={() =>
            (window.location.href = "https://www.newmoveis.com.br")
          }
        >
          <BannerBoxText>
            <span>Clique Aqui</span> e <br />
            Veja mais <br />
            Ambientes New
          </BannerBoxText>
        </BannerBox>
      </BannerContainer>
      <BannerContainerMobile>
        <StaticImage
          src="../images/banner-bottom-mobile.jpg"
          alt="Home Styling"
        />
        <BannerBox
          onClick={() =>
            (window.location.href = "https://www.newmoveis.com.br")
          }
        >
          <BannerBoxText>
            <span>Clique Aqui</span> e <br />
            Veja mais <br />
            Ambientes New
          </BannerBoxText>
        </BannerBox>
      </BannerContainerMobile>
    </>
  );
};
