import axios from "axios";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import styled from "styled-components";
import { media } from "../media";
import { Container, ValidationError } from "./Components";
import { FormGroup, InputField, Label } from "./InputField";

const investmentOptions = [
  {
    value: "ENTRE R$ 20.000,00 e 40.000,00",
    label: "Entre R$20.000 e R$40.000",
  },
  { value: "Acima de R$40.000,00", label: "Acima de R$40.000,00" },
];

const FormContainer = styled.div`
  width: 80%;
  margin: auto;
  color: #fff;
  ${media.up("lg")} {
    width: 600px;
    padding: 32px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 32px;
  background: #649767;
`;

const FormTitle = styled.h3`
  font-size: 32px;
  text-transform: uppercase;
  margin: 36px auto;
  text-align: center;
  font-weight: 300;
`;

const Button = styled.button`
  background-color: #fff;
  color: #649767;
  width: 100%;
  margin: auto;
  text-transform: uppercase;
  border-width: 0;
  padding: 10px;
  font-size: 22px;
`;

interface Option {
  value: string;
  label: string;
}
interface FormValues {
  name: string;
  email: string;
  phone: string;
  investment: Option;
}

export const FSPForm: React.FC = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [done, setDone] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const submit = async (values: FormValues) => {
    setIsSaving(true);
    const data = {
      ...values,
      exp: values.investment.value,
    };
    try {
      const res = await axios.post("/email.php", data);
      setDone(true);
      reset();
      console.log(res);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsSaving(false);
    }
  };

  const renderForm = () => {
    if (!done) {
      return (
        <Wrapper>
          <FormContainer>
            <FormTitle>Solicite Orçamento</FormTitle>
            <form onSubmit={handleSubmit(submit)}>
              <InputField
                name="name"
                register={register}
                label="Nome*"
                rules={{ required: true }}
                errors={errors}
                maxLength={50}
              />

              <InputField
                name="email"
                register={register}
                label="E-mail*"
                rules={{ required: true }}
                errors={errors}
              />

              <InputField
                name="phone"
                register={register}
                label="Telefone*"
                rules={{ required: true }}
                errors={errors}
              />

              <FormGroup>
                <Label htmlFor="investment">Expectativa de investimento*</Label>
                <Controller
                  control={control}
                  name="investment"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={investmentOptions}
                      placeholder="Selecione"
                      noOptionsMessage="Nenhum resultado"
                      isSearchable={false}
                      styles={{
                        container: (base) => ({
                          ...base,
                          flex: 1,
                          width: "100%",
                        }),
                        input: (base) => ({
                          ...base,
                          background: "transparent",
                        }),
                      }}
                    />
                  )}
                />
                {errors && errors.investment ? (
                  <ValidationError>Preenchimento inválido</ValidationError>
                ) : null}
              </FormGroup>
              <InputField
                name="message"
                register={register}
                label="Considerações Adicionais"
                errors={errors}
                isTextArea
              />
              <FormGroup>
                <Button type="submit" disabled={isSaving}>
                  {isSaving && "Enviando..."}
                  {!isSaving && "Enviar"}
                </Button>
              </FormGroup>
            </form>
          </FormContainer>
        </Wrapper>
      );
    }
    return null;
  };

  const renderSuccess = () => {
    if (done) {
      return (
        <FormContainer>
          <div
            style={{
              minHeight: 775,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormTitle>Obrigado</FormTitle>
            <p style={{ textAlign: "center", fontSize: 18, marginBottom: 32 }}>
              Entraremos em contato em breve!
            </p>
            <Button onClick={() => setDone(false)}>Ok</Button>
          </div>
        </FormContainer>
      );
    }
    return null;
  };

  return (
    <>
      {renderForm()}
      <Container>{renderSuccess()}</Container>
    </>
  );
};
